/* eslint-disable max-lines */
// @ts-check
import { SweftOperators } from "@app/grid/advancedFilters/operators";
import filters from "@app/workspace/config/filters.json";
import { adminStaticWorkspaceConfig, dashboardStaticWorkspaceConfig, superAdminStaticWorkspaceConfig } from "@app/workspace/contexts/workspaceConfigs";
import { getObjectPathValue, isObject } from "@app/common/utils";

/** ========= Functions used by workspace setup ========= */


export const filterPredicateMap = {
    emptyCells: (/** @type {unknown} */ unusedFilterValueArr, /** @type {string | unknown[]} */ cellValue) => {
        if (cellValue === null || cellValue === undefined) {
            return true;
        }

        if (typeof cellValue === "string") {
            return !cellValue;
        }

        if (Array.isArray(cellValue)) {
            return cellValue.length === 0;
        }

        if (isObject(cellValue)) {
            return Object.keys(cellValue).length === 0;
        }

        return false;
    },
    notEmptyCells: (/** @type {unknown} */ unusedFilterValueArr, /** @type {string | unknown[]} */ cellValue) => {
        return !filterPredicateMap.emptyCells(unusedFilterValueArr, cellValue);
    },
    equals: ([filterValue], /** @type {string} */ cellValue) => {
        if (typeof cellValue === "string" && typeof filterValue === "string") {
            return cellValue?.toLowerCase() === filterValue?.toLowerCase();
        }
        return cellValue === filterValue || `${cellValue}` === `${filterValue}`;
    },
    greaterThan: ([filterValue], /** @type {number} */ cellValue) => cellValue != null && cellValue > filterValue,
    greaterThanOrEquals: function ([filterValue], /** @type {unknown} */ cellValue) {
        return this.greaterThan([filterValue], cellValue) || cellValue === filterValue;
    },
    lessThan: ([filterValue], /** @type {number} */ cellValue) => cellValue != null && cellValue < filterValue,
    lessThanOrEquals: function ([filterValue], /** @type {unknown} */ cellValue) {
        return this.lessThan([filterValue], cellValue) || cellValue === filterValue;
    },
    notEquals: ([filterValue], /** @type {unknown} */ cellValue) => cellValue !== filterValue,
    zeroCriteria: () => false,
    between: ([filterValue, filterValue2], /** @type {number} */ cellValue) => cellValue != null && filterValue < cellValue && filterValue2 > cellValue,
    dateBetween: ([filterValue, filterValue2], /** @type {unknown} */ cellValue) => SweftOperators.SweftDateBetween(cellValue, filterValue, filterValue2),
    dateEquals: ([filterValue], /** @type {unknown} */ cellValue) => cellValue != null && SweftOperators.SweftDateEqual(cellValue, filterValue),
    dateNotEquals: ([filterValue], /** @type {unknown} */ cellValue) => cellValue != null && SweftOperators.SweftDateNoEqual(cellValue, filterValue),
    dateLessThan: ([filterValue], /** @type {unknown} */ cellValue) => cellValue != null && SweftOperators.SweftDateLess(cellValue, filterValue),
    dateLessThanOrEquals: ([filterValue], /** @type {unknown} */ cellValue) => cellValue != null && SweftOperators.SweftDateLessOrEqual(cellValue, filterValue),
    dateGreaterThan: ([filterValue], /** @type {unknown} */ cellValue) => cellValue != null && SweftOperators.SweftDateGreater(cellValue, filterValue),
    dateGreaterThanOrEquals: ([filterValue], /** @type {unknown} */ cellValue) => cellValue != null && SweftOperators.SweftDateGreaterOrEqual(cellValue, filterValue),
    inList: ([filterValue], /** @type {string} */ cellValue) => {
        let searchElement = filterValue;
        if (searchElement?.includes(',')) {
            searchElement = searchElement.split(',');
        }
        if (searchElement?.includes(" ")) {
            searchElement = searchElement.split(' ');
        }
        if (Array.isArray(searchElement)) {
            return searchElement?.some((item) => {
                if (typeof cellValue === 'string') {
                    return cellValue?.toLowerCase() === item?.toLowerCase();
                }
                return cellValue === item || `${cellValue}` === `${filterValue}`;
            });
        }
        return false;
    }
};

/**
 * Different ways that column menus can filter a column
 * @param {string} displayKey
 * @returns {(filters:unknown[], val:unknown) => boolean}
 */
export const getFilterPredicate = displayKey => {
    if (filterPredicateMap[displayKey]) {
        return filterPredicateMap[displayKey];
    }
    return false;
};

export const getDateFilters = (/** @type {unknown[]} */ filters) => {
    return filters.map((/** @type {{ displayKey: string; displayName: string; }} */ operator) => {
        const captalizeFirstChar = operator.displayKey.charAt(0).toUpperCase() + operator.displayKey.slice(1);
        return {
            displayKey: operator.displayKey,
            displayName: operator.displayName,
            numberOfInputs: 1,
            predicate: getFilterPredicate(`date${captalizeFirstChar}`)
        };
    });
};

/* eslint-disable complexity */
/**
 * Internal function to set different filters for specific column types
 * @param {string} baseType
 * @param {Array} filters
 * @returns {unknown[]}
 */
export const addFilterDoc = (/** @type {{ dateFilters: unknown[]; }} */ filters, baseType, /** @type {string} */ format, /** @type {unknown[]} */ dynamicFilterList = []) => {
    let filterSet = [];
    const lowercaseFormat = format;
    const lowercaseBaseType = baseType;

    const dynamicFiltersMap = {
        in: {
            displayKey: "inList",
            displayName: "In",
            predicate: getFilterPredicate("inList"),
            numberOfInputs: 1,
            suppressAndOrCondition: true
        },
        between: {
            displayKey: "between",
            displayName: "Between",
            numberOfInputs: 2,
            predicate: baseType !== "DATE" ? getFilterPredicate("between") : getFilterPredicate("dateBetween"),
            suppressAndOrCondition: true,
        },
        isEmpty: {
            displayKey: 'isEmpty',
            displayName: 'Is Empty',
            predicate: getFilterPredicate("emptyCells"),
            numberOfInputs: 0,
            suppressAndOrCondition: true
        },
        isNotEmpty: {
            displayKey: 'isNotEmpty',
            displayName: 'Is Not Empty',
            predicate: getFilterPredicate("notEmptyCells"),
            numberOfInputs: 0,
            suppressAndOrCondition: true
        }
    };

    const dynamicFiltersSetter = dynamicFilterList.map(filter => dynamicFiltersMap[filter]);
    const stringFiltersList = ['contains', ...dynamicFiltersSetter, dynamicFiltersMap.isEmpty, 'equals', 'notEqual', 'endsWith', 'startsWith'];
    const booleanFiltersList = ['empty', dynamicFiltersMap.isEmpty, dynamicFiltersMap.isNotEmpty];
    const numericFiltersList = ['empty', dynamicFiltersMap.isEmpty, 'equals', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual', 'notEqual', dynamicFiltersMap.between];
    switch (lowercaseBaseType) {
        case 'string':
            switch (lowercaseFormat) {
                case 'defaultstring':
                case 'nesteddropdown':
                case 'longtext':
                    filterSet = stringFiltersList;
                    break;
                case 'dropdown':
                case 'nesteddropdown':
                    filterSet = ['equals', 'notEqual'];
                    break;
                case 'messages':
                    const hasMessages = {
                        displayKey: 'hasMessages',
                        displayName: 'Has Messages',
                        predicate: (/** @type {unknown} */ _, /** @type {unknown} */ cellValue) => cellValue !== null && cellValue,
                        numberOfInputs: 0,
                        suppressAndOrCondition: true
                    };
                    filterSet = ['empty', dynamicFiltersMap.isEmpty, hasMessages];
                    break;
                default: {
                    filterSet = stringFiltersList;
                    break;
                }
            }
            break;
        case 'integer':
        case 'float':
        case 'numberlist':
        case 'stringlist':
            switch (lowercaseFormat) {
                case 'list':
                case 'defaultint':
                case 'defaultdouble':
                case 'defaultlong':
                case 'currency':
                case 'percentage':
                    filterSet = numericFiltersList;
                    break;
                default: {
                    filterSet = numericFiltersList;
                    break;
                }
            }
            break;
        case 'relationship':
            switch (lowercaseFormat) {
                case 'defaultstring':
                case 'longtext':
                    filterSet = stringFiltersList;
                    break;
                case 'dropdown':
                case 'nesteddropdown':
                    filterSet = ['equals', 'notEqual'];
                    break;
                case 'boolean':
                    filterSet = booleanFiltersList;
                    break;
                default: {
                    filterSet = stringFiltersList;
                    break;
                }
            }
            break;
        case 'formula':
            switch (lowercaseFormat) {
                case 'defaultstring':
                case 'nesteddropdown':
                    filterSet = stringFiltersList;
                    break;
                case 'defaultint':
                case 'defaultdouble':
                case 'defaultlong':
                case 'currency':
                case 'percentage':
                    filterSet = numericFiltersList;
                    break;
                case 'date':
                    const setDateFilters = getDateFilters(filters.dateFilters);
                    setDateFilters?.push(dynamicFiltersMap.between);
                    filterSet = setDateFilters;
                    break;
                default: {
                    filterSet = stringFiltersList;
                    break;
                }
            }
            break;
        case 'date':
            switch (lowercaseFormat) {
                case 'date':
                case 'datetime':
                case 'datestamp':
                case 'datetimestamp':
                    const setDateFilters = getDateFilters(filters.dateFilters);
                    setDateFilters?.push(dynamicFiltersMap.between);
                    filterSet = [...setDateFilters, dynamicFiltersMap.isEmpty, dynamicFiltersMap.isNotEmpty];
                    break;
            }
            break;
        case 'file':
        case 'image': {
            const hasFile = {
                displayKey: 'hasFile',
                displayName: 'Has File',
                predicate: (/** @type {unknown} */ _, /** @type {unknown} */ cellValue) => cellValue !== null && cellValue,
                numberOfInputs: 0,
                suppressAndOrCondition: true
            };
            filterSet = ['empty', dynamicFiltersMap.isEmpty, hasFile];
            break;
        }
        case 'cta':
        case 'boolean': {
            const isTrue = {
                displayKey: 'equalsTrue',
                displayName: 'True',
                predicate: (/** @type {unknown} */ _, /** @type {boolean} */ cellValue) => Boolean(cellValue) === true,
                numberOfInputs: 0,
                suppressAndOrCondition: true
            };
            const isFalse = {
                displayKey: 'equalsFalse',
                displayName: 'False',
                predicate: (/** @type {unknown} */ _, /** @type {boolean} */ cellValue) => Boolean(cellValue) === false || !cellValue,
                numberOfInputs: 0,
                suppressAndOrCondition: true
            };
            filterSet = ['empty', isTrue, isFalse];
            break;
        }
        case 'gid': {
            filterSet = stringFiltersList;
            break;
        }
        default: {
            filterSet = stringFiltersList;
            break;
        }
    }

    return filterSet;
};
/* eslint-enable complexity */

/**
 * @prop {Object<PartialColumnDefinition>} column
 * @prop {SweftRoleConfig} roleObj
 * @returns {FullColumnDefinition}
 */
export const setUpColumnDefinition = ({ column, roleObj } = {}) => {
    let filterType;
    const baseType = column.baseType?.toLowerCase();
    const format = column.format?.toLowerCase();
    const dynamicFilterList = column.specifications?.colDef?.dynamicFilterList;
    const getFiltersOptions = addFilterDoc(filters, baseType, format, dynamicFilterList);
    const suppressAndOrCondition = getFiltersOptions?.filter(filter => filter?.suppressAndOrCondition === true).length > 0;

    switch (baseType) {
        case 'string':
            switch (format) {
                case 'defaultstring':
                    filterType = 'agTextColumnFilter';
                    break;
                default: {
                    filterType = 'agTextColumnFilter';
                    break;
                }
            }
            break;
        case 'integer':
        case 'float':
        case 'numberlist':
        case 'stringlist':
            switch (format) {
                case 'list':
                case 'defaultint':
                case 'defaultdouble':
                case 'defaultlong':
                case 'currency':
                case 'percentage':
                    filterType = 'agNumberColumnFilter';
                    break;
                default: {
                    filterType = 'agNumberColumnFilter';
                    break;
                }
            }
            break;
        case 'formula':
            switch (format) {
                case 'defaultstring':
                    filterType = 'agTextColumnFilter';
                    break;
                case 'list':
                case 'defaultint':
                case 'defaultdouble':
                case 'defaultlong':
                case 'currency':
                case 'percentage':
                    filterType = 'agNumberColumnFilter';
                    break;
                default: {
                    filterType = 'agNumberColumnFilter';
                    break;
                }
            }
            break;
        case 'date':
            filterType = 'agDateColumnFilter';
            break;
        default:
            filterType = 'agTextColumnFilter';
            break;
    }

    const permission = roleObj.roleId === 'Admin' ? 'WRITE' : roleObj.columnList[`${column.schemaId}`];
    return {
        ...column,
        editable: permission === "WRITE",
        permission,
        filter: filterType,
        filterParams: {
            buttons: ['clear'],
            filterOptions: getFiltersOptions,
            suppressAndOrCondition: suppressAndOrCondition,
            baseType: baseType,
            format: format
        },
        filterValueGetter: (/** @type {object} */ params) => {
            if (params.column.filterActive && ['task', 'project', 'deliverable'].includes(params?.data?.entity?.toLowerCase())) {
                const getNestedData = getObjectPathValue({ obj: params.data, path: params.colDef.field });
                return getNestedData;
            }
            if (params?.data[params.colDef.field] && isObject(params?.data[params.colDef.field])) {
                if (params?.colDef?.cellEditorParams?.relatedEntityAttributeToDisplay) {
                    const _filterDisplayValue = params.colDef.cellEditorParams.relatedEntityAttributeToDisplay;
                    return params.data[params.colDef.field][_filterDisplayValue];
                }
                return params.data[params.colDef.field].displayName;
            }
            if (params?.data[params.colDef.field] && Array.isArray(params?.data[params.colDef.field])) {
                return params?.data[params.colDef.field].map((data) => {
                    const _filterDisplayValue = params.colDef.cellEditorParams.relatedEntityAttributeToDisplay;
                    return data[_filterDisplayValue];
                });
            }
            if (params?.data[params.colDef.field] && Array.isArray(params?.data[params.colDef.field])) {
                return params?.data[params.colDef.field].map((data) => {
                    const _filterDisplayValue = params.colDef.cellEditorParams.relatedEntityAttributeToDisplay;
                    return data[_filterDisplayValue];
                });
            }
            if (!!params.data[params.colDef.field] && !!params?.colDef?.cellRendererParams?.sampleCardTemplateConfig) {
                return params?.data[params.colDef.field].length;
            }
            // for grouped columns
            if (params?.column?.parent && params?.column?.rowGroupActive && params?.column?.colId.includes('.')) {
                const splitValues = params?.column?.colId.split('.');
                const getNestedData = splitValues.reduce((/** @type {{ [x: string]: any; }} */ data, /** @type {string | number} */ field) => {
                    data = data[field];
                    return data;
                }, params?.data);

                return getNestedData;
            }
            return params.data[params.colDef.field];
        },
        headerName: column.displayName,
        width: parseInt(column.defaultWidth),
    };
};

/**
 * @prop {Object<FullColumnDefinition>} column - column
 * @prop {Array<string>} subEntities - subEntities
 * @prop {string} entity - entity
 * @returns Object<FullColumnDefinition>
 */
export const modifySubEntityColumn = ({ column, relatedAttributes } = {}) => {
    const relatedAttributeExists = relatedAttributes.find((/** @type {{ relation: string; }} */ col) => col.relation === column.entity);
    if (!relatedAttributeExists) {
        return column;
    }

    const relatedAttributeField = relatedAttributeExists.schemaId.split(".")[1];

    const [relatedEntity, ...restOfColumnId] = column.colId.split(".");

    const updatedColumn = {
        ...column,
        colId: `${relatedAttributeField}.${restOfColumnId.join(".")}`,
        field: `${relatedAttributeField}.${restOfColumnId.join(".")}`,
    };

    return updatedColumn;
};

/**
 *
 * @prop {string} entity
 * @prop {Array<PartialColumnDefinition>} columnList
 * @prop {Entitlement} entitlement
 * @returns {Array<FullColumnDefinition>}
 */
export const generateColumnDefinitionList = ({ entity, columnList = [], entityToRelatedAttributeMap, currentUserRole }) => {
    const relatedAttributes = entityToRelatedAttributeMap?.[entity] || [];

    if (!currentUserRole) {
        return [];
    }

    return columnList
        ?.map((/** @type {unknown} */ column) => setUpColumnDefinition({ column, roleObj: currentUserRole }))
        .map((/** @type {unknown} */ column) => modifySubEntityColumn({ column, relatedAttributes }));
};

/**
 * Find the specialty in the user object for the given workspace, if it exists
 * @property user
 * @property currentWorkspace
 * @returns {null|*}
 */
export const getWorkspaceSpecialtyForUser = ({ user = {}, currentWorkspace = {} } = {}) => {
    let parsedSpecialty;
    try {
        parsedSpecialty = JSON.parse(user?.speciality);
    } catch (error) {
        return null;
    }

    if (!Array.isArray(parsedSpecialty) || parsedSpecialty.length === 0) {
        return null;
    }

    if (currentWorkspace.id === "dashboard") {
        return parsedSpecialty[0];
    }

    const currentWorkspaceSpecialty = parsedSpecialty && parsedSpecialty?.find((/** @type {{ workspace: unknown; }} */ getWorkspace) => getWorkspace?.workspace === currentWorkspace?.primaryEntity);
    return currentWorkspaceSpecialty ?? null;
};

/**
 *
 * @param isAdmin
 * @param isSuperAdmin
 * @param enabledWorkspaceList
 * @returns {{entitledWorkspaceList: unknown, unentitledWorkspaceList: SweftWorkspaceStaticConfig[]}}
 */
export const getStaticAndDynamicWorkspaceList = ({ enabledWorkspaceList, isAdmin, isSuperAdmin }) => {
    /**
     * @type {Array<SweftWorkspaceStaticConfig>}
     */
    const staticConfigs = [dashboardStaticWorkspaceConfig];

    const enabledDynamicWorkspaceList = enabledWorkspaceList.map((/** @type {{ specifications: { id: unknown; }; configurationId: unknown; }} */ workspace) => {
        return {
            ...workspace,
            id: workspace?.specifications?.id ? workspace.specifications.id : workspace?.configurationId
        };
    });

    let workspaceList = [...staticConfigs, ...enabledDynamicWorkspaceList];

    if (isAdmin) {
        workspaceList = [...workspaceList, adminStaticWorkspaceConfig];
    }

    if (isSuperAdmin) {
        workspaceList = [...workspaceList, superAdminStaticWorkspaceConfig];
    }


    return {
        workspaceList
    };
};
