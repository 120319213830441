// @ts-check
import request from './request';
import { baseConfig } from '@app/config/baseConfig';
import { base64Encode } from "@app/common/utils";
import { sortBy } from "lodash";
import { flagNames, isFlagOn } from "@app/common/featureFlags";
// @ts-ignore
const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}`;

export const SavedQueryService = {
    getSavedQueryListByReferenceIdList: async ({ idList = [] }) => {
        try {
            const response = await request({
                method: "GET",
                url: `${api}/saved-queries/references`
            });
            const savedQueryList = sortBy(response.data.response, (item) => new Date(item.modified).getTime()).reverse();
            return { data: savedQueryList.filter(({ referenceId }) => idList.includes(referenceId)) };
        } catch (e) {
            console.log("Error getting saved query reference list: ", e);
        }
    },
    getEntitySavedQueryCounts: async ({ entity }) => {
        let path = "data";
        if (isFlagOn(flagNames.readGraphData)) {
            path = "graph-data";
        }
        const response = await request({
            method: "GET",
            url: `${api}/${path}/${entity}/saved-query-counts`
        });
        return response?.data;
    },
    getEntitySavedQueryCountsForSavedQueryIdList: async ({ entity, savedQueryIdList, additionalJsonLogicQueryObject, additionalSavedQueryIdList }) => {
        const params = new URLSearchParams();

        if (savedQueryIdList) {
            params.set("saved-query-id-list", savedQueryIdList.join(","));
        }

        if (additionalJsonLogicQueryObject) {
            params.set("jlQuery", base64Encode(JSON.stringify(additionalJsonLogicQueryObject)));
        }

        if (additionalSavedQueryIdList) {
            params.set("additional-saved-query-id-list", additionalSavedQueryIdList.join(","));
        }

        let path = "data";
        if (isFlagOn(flagNames.readGraphData)) {
            path = "graph-data";
        }
        const response = await request({
            method: "GET",
            url: `${api}/${path}/${entity}/saved-query-counts${params.toString() ? `?${params.toString()}` : ''}`
        });
        return response?.data;
    }
};
